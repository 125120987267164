import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLedgerList() {
  // Use toast
  const toast = useToast()

  const refLedgerListTable = ref(null)

  const perPage = ref(5)
  const totalLedgers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refLedgerListTable.value ? refLedgerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLedgers.value,
    }
  })

  const refetchData = () => {
    refLedgerListTable.value.refresh()
  }

  watch([currentPage, perPage, branchFilter], () => {
    refetchData()
  })

  const branchOptions = ref(JSON.parse(JSON.stringify([])))
  store
    .dispatch('common/fetchBranches', { id_name: 1 })
    .then(response => {
      const { branches } = response.data.data
      for (let i = 0; i < branches.length; i += 1) {
        branchOptions.value.push({ label: branches[i].name, value: branches[i].id.toString() })
      }
    })
    .catch(error => {
      console.log(error)
    })

  const fetchLedgers = (ctx, callback) => {
    store
      .dispatch('ledger/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        branch_id: branchFilter.value,
      })
      .then(response => {
        const { ledgers, total } = response.data.data

        callback(ledgers)
        totalLedgers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting ledgers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchLedgers,
    perPage,
    currentPage,
    totalLedgers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLedgerListTable,
    refetchData,
    branchFilter,
    branchOptions,
  }
}
